@font-face {
  font-family: myFirstFont;
  src: url("https://fonts.googleapis.com/css?family=Trirong");
}

::selection {
  color: white;
  background: rgb(51, 51, 51);
}

::-moz-selection {
  color: white;
  background: rgb(51, 51, 51);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

body {
  margin: 0;
  background-color: #F5F5F5;
}

h1, h2, h3, h4, h5, h6, p, span {
  font-family: Trirong sans-serif;
}

p {
  line-height: 30px;
}

.page-width {
  margin: 0 60px;
  max-width: 1300px;
  width: 100%;
}

@media (max-width: 1050px) {
  .page-width {
    margin: 0 20px;
  }
}

@media (max-width: 720px) {
  .page-width {
    margin: auto;
    padding: 0 15px 15px;
  }
}
